import React, { useEffect, useState } from "react";
import closeIcon from "../../Assets/close.svg";
import { useDispatch } from "react-redux";
import {
  createRosterManagementPOTAPI,
  fetchWorkerWithRoleAction,
  updateRosterManagementPUTAPI,
} from "../../Actions/RosterManagementAction";
import DatePicker from "react-multi-date-picker";
import {
  CANVAS_BOTTOM_STYLE,
  ROASTER_CREATE_SUCCESS_MESSAGE,
  ROASTER_UPDATE_SUCCESS_MESSAGE,
  SUCCESS,
  WORKER_NAME_LENGTH,
  ADD_ROASTER_TITLE,
  EDIT_ROASTER_TITLE,
  END_DATE_ERROR,
  START_DATE_ERROR,
  SHIFT_TIME_ERROR,
  SHIFT_LOCATION_ERROR,
  STATION_NAME_ERROR,
  DATE_VALIDATION_ERROR,
  formatDate,
  DATE_FIELD_STYLE,
  DATE_RANGE_ERROR,
  getMaxDateRangeOnCreate,
  ROASTER_EMPID,
  EMP_ID_ERROR,
  ROASTER_DEPT_NAME,
  floorRegex,
  isSearchRegex,
} from "../../Utils";
import {
  ROASTER_CANVAS_ID,
  ENTER_KEY,
  MIN_SEARCH_LENGTH,
  ZERO,
} from "../../Utils";
import SuccessSnack from "../../ReUsable-Components/Success-Snack";
import ErrorSnack from "../../ReUsable-Components/Error-Snack";
import CancelModal from "../../ReUsable-Components/Cancel-Modal";
import {
  ROASTER_WORKER_NAME,
  ROASTER_ROLENAME,
  ROASTER_LOCATION,
  ROASTER_STATIONNAME,
  ROASTER_TIMESLOTS,
} from "../../Utils";
import secureLocalStorage from "react-secure-storage";
import dropDownArrow from "../../Assets/Dropdown_arrow.svg";
import search from "../../Assets/search.svg";

const AddRosterManagement = (props) => {
  const dispatch = useDispatch();
  const [shiftTimeSlotLists, setShiftTimeSlotLists] = useState([]);
  const [stationLists, setStationLists] = useState([]);
  const [floorLists, setFloorDetailsLists] = useState([]);
  const [error, setErrors] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const propsRoasterListFn = props?.roasterManagementListsFn;
  const editFunction = props?.onEditRoster;
  const [roleSearchTerm, setRoleSearchTerm] = useState("");
  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [allWorkersList,setAllWorkersList] = useState([]);
  const [workerDetails,setWorkerDetails] = useState([]);
  const [empIdList,setEmpIdList] = useState([]);
  

  const firstNameEditDetails = props?.editDetails?.firstName;
  const workerIdEditDetails = props?.editDetails?.workerId;
  const roleNameEditDetails = props?.editDetails?.role;
  const roleIdEditDetails = props?.editDetails?.roleId;
  const deptIdEditDetails = props?.editDetails?.departmentId;
  const timeIdEditDetails = props?.editDetails?.shiftTimeId;
  const startTimeEditDetails = props?.editDetails?.shiftStartTime;
  const endTimeEditDetails = props?.editDetails?.shiftEndTime;
  const stationNameEditDetails = props?.editDetails?.stationName;
  const stationIdEditDetails = props?.editDetails?.stationId;
  const floorNameEditDetails = props?.editDetails?.shiftLocation;
  const floorIdEditDetails = props?.editDetails?.locationId;
  const startDateEditDetails = props?.editDetails?.startDate;
  const endDateEditDetails = props?.editDetails?.endDate;
  const rosterIdEditDetails = props?.editDetails?.rosterId;
  const lastNameEditDetails = props?.editDetails?.lastName;
  const departmentNameEditDetails = props?.editDetails?.departmentName;
  const empIdEditDetails = props?.editDetails?.empId
  const roasterEditDetails = props?.editDetails;

  function extractEmpIds(data) {
    return data
        .filter(item => item.empId !== null) 
        .map(item => ({
            empId: item.empId
        }));
}
  
  const fetchWorkerWithRole = () => {
    dispatch(fetchWorkerWithRoleAction()).then(
      (response) => {
        if (response?.data?.body) {
          const filteredData = extractEmpIds(response.data.body);
          setEmpIdList(filteredData)
          setFilteredRoleList([...filteredData]);
          setAllWorkersList(response.data.body);
        }
         else {
          setFilteredRoleList([]);
        }
      },
      (error) => {
        setErrorMsg(error);
        handleErrorClickInAnotherComponent();
        setFilteredRoleList([]);
      }
    );
  };

  useEffect(
    () => {
      setShiftTimeSlotLists(
        JSON.parse(secureLocalStorage.getItem("timeSlotsList"))
      );
      setStationLists(JSON.parse(secureLocalStorage.getItem("stationsList")));
      setFloorDetailsLists(
        JSON.parse(secureLocalStorage.getItem("floorsList"))
      );

      setWorkerDetails({
        workerName:firstNameEditDetails
            ? firstNameEditDetails + " " + lastNameEditDetails :ROASTER_WORKER_NAME,
        roleName: roleNameEditDetails ?? ROASTER_ROLENAME,
        workerId: workerIdEditDetails ?? "",
        roleId: roleIdEditDetails ?? "",
        empId: empIdEditDetails ?? ROASTER_EMPID,
        deptId: deptIdEditDetails ?? "",
        deptName:departmentNameEditDetails?? ROASTER_DEPT_NAME,
      })

      setShiftTimeSlots({
        shiftTimeSlots: firstNameEditDetails
          ? startTimeEditDetails + "-" + endTimeEditDetails
          : ROASTER_TIMESLOTS,
        shiftTimeId: timeIdEditDetails ? timeIdEditDetails : "",
      });
      setShiftStation({
        shiftStationName: stationNameEditDetails
          ? stationNameEditDetails
          : ROASTER_STATIONNAME,
        shiftStationId: stationIdEditDetails ? stationIdEditDetails : "",
      });
      setShiftLocation({
        shiftLocation: floorNameEditDetails
          ? floorNameEditDetails
          : ROASTER_LOCATION,
        locationId: floorIdEditDetails ? floorIdEditDetails : "",
      });
      setDateRange(roasterEditDetails?[new Date(startDateEditDetails),new Date(endDateEditDetails)]:[])
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  useEffect(() => {
    fetchWorkerWithRole();
  },// eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const initialValues = () => {
    setWorkerDetails({
      workerName:firstNameEditDetails
          ? firstNameEditDetails + " " + lastNameEditDetails :ROASTER_WORKER_NAME,
      roleName: roleNameEditDetails ?? ROASTER_ROLENAME,
      workerId: workerIdEditDetails ?? "",
      roleId: roleIdEditDetails ?? "",
      empId:empIdEditDetails ?? ROASTER_EMPID,
      deptId: deptIdEditDetails ?? "",
      deptName:departmentNameEditDetails?? ROASTER_DEPT_NAME,
    }); 

    setShiftTimeSlots({
      shiftTimeSlots: firstNameEditDetails
        ? startTimeEditDetails + "-" + endTimeEditDetails
        : ROASTER_TIMESLOTS,
      shiftTimeId: timeIdEditDetails ? timeIdEditDetails : "",
    });
    setShiftStation({
      shiftStationName: stationNameEditDetails
        ? stationNameEditDetails
        : ROASTER_STATIONNAME,
      shiftStationId: stationIdEditDetails ? stationIdEditDetails : "",
    });
    setShiftLocation({
      shiftLocation: floorNameEditDetails
        ? floorNameEditDetails
        : ROASTER_LOCATION,
      locationId: floorIdEditDetails ? floorIdEditDetails : "",
    });
    setDateRange(roasterEditDetails?[new Date(startDateEditDetails),new Date(endDateEditDetails)]:[])
  };

  const resetfn = () => {
    resetErrors();
    initialValues();
    setRoleSearchTerm("");
    setFilteredRoleList(empIdList);
  };

  const clearPropsfn = () => {
    editFunction(null);
    resetErrors();
  };
  
  const onDateChange = (selectedDates) => {
    setDateRange([selectedDates[0]?.format(), selectedDates[1]?.format()]);
  };

  const [shiftTimeSlots, setShiftTimeSlots] = useState({
    shiftTimeSlots: firstNameEditDetails
      ? startTimeEditDetails + "-" + endTimeEditDetails
      : ROASTER_TIMESLOTS,
    shiftTimeId: timeIdEditDetails ? timeIdEditDetails : "",
  });
  const [shiftStation, setShiftStation] = useState({
    shiftStationName: stationNameEditDetails
      ? stationNameEditDetails
      : ROASTER_STATIONNAME,
    shiftStationId: stationIdEditDetails ? stationIdEditDetails : "",
  });
  const [shiftLocation, setShiftLocation] = useState({
    shiftLocation: floorNameEditDetails
      ? floorNameEditDetails
      : ROASTER_LOCATION,
    locationId: floorIdEditDetails ? floorIdEditDetails : "",
  });
  const [dateValid, setDateValid] = useState(true);

  const handleChange = (e, label) => {

  switch (label) {

      case workerDetails?.empId:
        const worker = allWorkersList?.find(worker => worker.empId === e?.empId);
        setWorkerDetails({
          empId:worker?.empId,
          roleId: worker?.roleId,
          roleName:worker?.role,
          workerId:worker?.workerId,
          workerName:worker?.firstName+" "+worker?.lastName,
          deptId:worker?.department,
          deptName:worker?.departmentName,
        });
        setRoleSearchTerm("");
        setFilteredRoleList(empIdList);
        resetErrors();
        break;

      case shiftTimeSlots.shiftTimeSlots:
        setShiftTimeSlots({
          shiftTimeSlots: e?.shiftStartTime + " - " + e?.shiftEndTime,
          shiftTimeId: e?.shiftTimeId,
        });
        resetErrors();
        break;

      case shiftStation.shiftStationName:
        setShiftStation({
          shiftStationName: e?.stationName,
          shiftStationId: e?.shiftStationId,
        });
        resetErrors();
        break;

      case shiftLocation.shiftLocation:
        setShiftLocation({
          shiftLocation: e?.shiftLocation,
          locationId: e?.shiftLocationId,
        });
        resetErrors();
        break;

      default:
        break;
    }
  };

  let errorsMsg = {};
  const validateValues = (
    workerDetails,
    shiftStation,
    shiftLocation,
    shiftTimeSlots,
    startDate,
    endDate
  ) => {
    if(!workerDetails?.roleId){
      errorsMsg.empId = EMP_ID_ERROR
    }
    if (!shiftStation?.shiftStationId) {
      errorsMsg.stationName = STATION_NAME_ERROR;
    }
    if (!shiftLocation?.locationId) {
      errorsMsg.shiftLocation = SHIFT_LOCATION_ERROR;
    }
    if (!shiftTimeSlots?.shiftTimeId) {
      errorsMsg.shiftTime = SHIFT_TIME_ERROR;
    }
    if (!startDate) {
      errorsMsg.startDate = START_DATE_ERROR;
    }
    if (!endDate) {
      errorsMsg.endDate = END_DATE_ERROR;
    }

    return errorsMsg;
  };

  const validateDates = (fromDate, toDate) => {
    const isValid = fromDate <= toDate;
    setDateValid(isValid);
  };

  let submitcdn =
    shiftStation?.shiftStationId &&
    shiftLocation?.locationId &&
    shiftTimeSlots?.shiftTimeId &&
    dateRange[0] !== new Date() &&
    dateRange[1] !== new Date() &&
    dateRange[0] !== (null || undefined) &&
    dateRange[1] !== (null || undefined);
  const submit = () => {
    setErrors(
      validateValues(
        workerDetails,
        shiftStation,
        shiftLocation,
        shiftTimeSlots,
        dateRange[0],
        dateRange[1]
      )
    );
    validateDates(dateRange[0],dateRange[1]);

    let payload = {};

    if (submitcdn) {
      if (dateValid) {
        if (!roasterEditDetails) {
          payload = {
            workerId: workerDetails?.workerId,
            stationId: shiftStation?.shiftStationId,
            locationId: shiftLocation?.locationId,
            departmentId: workerDetails?.deptId,
            roleId: workerDetails?.roleId,
            shiftTimeId: shiftTimeSlots?.shiftTimeId,
            startDate: formatDate(dateRange[0]),
            endDate: formatDate(dateRange[1]),
          };
   

          dispatch(createRosterManagementPOTAPI(payload)).then(
            (response) => {
              if (response?.data?.status === SUCCESS) {
                handleClickInAnotherComponent();
              } else {
                setErrorMsg(response);
                handleErrorClickInAnotherComponent();
              }
            },
            (error) => {
              setErrorMsg(error);
              handleErrorClickInAnotherComponent();
            }
          );
        } else {
          payload = {
            rosterId: rosterIdEditDetails,
            workerId: roasterEditDetails?.workerId,
            departmentId: roasterEditDetails?.departmentId,
            roleId: roasterEditDetails?.roleId,
            stationId: shiftStation?.shiftStationId,
            locationId: shiftLocation?.locationId,
            shiftTimeId: shiftTimeSlots?.shiftTimeId,
            startDate: formatDate(dateRange[0]),
            endDate: formatDate(dateRange[1]),
          };
          dispatch(
            updateRosterManagementPUTAPI(payload, rosterIdEditDetails)
          ).then(
            (response) => {
              if (response?.data?.status === SUCCESS) {
                handleClickInAnotherComponent();
              } else {
                setErrorMsg(response);
                handleErrorClickInAnotherComponent();
              }
            },
            (error) => {
              setErrorMsg(error);
              handleErrorClickInAnotherComponent();
            }
          );
        }
      } else {
        handleErrorClickInAnotherComponent();
      }
    }
  };

  const [isSnackOpen, setIsSnackOpen] = useState(false);

  const handleClickInAnotherComponent = () => {
    setIsSnackOpen(true);
  };

  const handleSnackClose = () => {
    setIsSnackOpen(false);
    handleReload();
  };

  const [isErrorSnackOpen, setIsErrorSnackOpen] = useState(false);

  const handleErrorClickInAnotherComponent = () => {
    setIsErrorSnackOpen(true);
  };

  const handleErrorSnackClose = () => {
    setIsErrorSnackOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRoleSearch = (e) => {
    if (roleSearchTerm.length === 0) {
      setFilteredRoleList(empIdList);
    } else if (roleSearchTerm?.length > MIN_SEARCH_LENGTH) {
      dispatch(fetchWorkerWithRoleAction(roleSearchTerm)).then(
        (response) => {
          const filteredData = extractEmpIds(response.data.body);
          setFilteredRoleList(filteredData);
        },
        (error) => {
          setErrorMsg(error);
          handleErrorClickInAnotherComponent();
        }
      );
    }
  };

  const cancelFn = () => {
    resetErrors();
    initialValues();
    handleReload();
    setShowModal(false);
  };

  const resetErrors = () => {
    setErrors({});
  };
  const handleReload = () => {
    document.getElementById(ROASTER_CANVAS_ID)?.click();
    propsRoasterListFn();
    editFunction(null);
  };

  let cancelFunRoaster =
    (
      workerDetails?.empId === ROASTER_EMPID &&
      shiftLocation?.shiftLocation === ROASTER_LOCATION &&
      shiftStation?.shiftStationName === ROASTER_STATIONNAME &&
      shiftTimeSlots?.shiftTimeSlots === ROASTER_TIMESLOTS) ||
    (
      shiftTimeSlots?.shiftTimeId === timeIdEditDetails &&
      shiftStation?.shiftStationName === stationNameEditDetails &&
      shiftLocation?.shiftLocation === floorNameEditDetails);

  const handleKeyPress = (e) => {
    if (e?.key === ENTER_KEY) {
      submit();
    }
  };

  const {pastDate,futureDate}=getMaxDateRangeOnCreate();

  const handleChangeForEmpId = (e) => {
    const {value } = e.target;
    if(isSearchRegex(value,floorRegex)){
      setRoleSearchTerm(value) 
    }
  }
  

  return (
    <>
      <div className="backgroundDiv h-100">
        <SuccessSnack
          open={isSnackOpen}
          onClose={handleSnackClose}
          successMessage={
            rosterIdEditDetails
              ? ROASTER_UPDATE_SUCCESS_MESSAGE
              : ROASTER_CREATE_SUCCESS_MESSAGE
          }
        />
        <ErrorSnack
          open={isErrorSnackOpen}
          onClose={handleErrorSnackClose}
          errorMessage={!dateValid ? DATE_VALIDATION_ERROR : errorMsg}
        />

        <span data-bs-dismiss="offcanvas" id={ROASTER_CANVAS_ID}></span>
        <div className="modalHeader border border-end-0 border-top-0 border-bottom-1 border-start-0 align-items-center d-flex justify-content-between p-2 ms-1">
          <span className="titleHeader">
            {roasterEditDetails ? EDIT_ROASTER_TITLE : ADD_ROASTER_TITLE}
          </span>

          {cancelFunRoaster ? (
            <img
              src={closeIcon}
              data-bs-dismiss="offcanvas"
              className="close-cursor"
              onClick={clearPropsfn}
              alt="img"
            />
          ) : (
            <img
              src={closeIcon}
              className="close-cursor"
              onClick={handleShowModal}
              alt="img"
            />
          )}
        </div>

        <div className="m-2">
          <div className="d-flex">
            <div className="w-100 m-2">
              <label className="labels mt-1">Emp Id</label>
              {rosterIdEditDetails ? "" : <span className="strike">*</span>}
              <div className="mt-1 w-100">
                {roleIdEditDetails ? (
                  <input
                    className="form-control"
                    value={
                      workerDetails?.empId?.length > WORKER_NAME_LENGTH
                        ? workerDetails?.empId?.substring(
                            0,
                            WORKER_NAME_LENGTH
                          ) + "..."
                        : workerDetails?.empId
                    }
                    disabled
                  ></input>
                ) : (
                  <div
                    className={
                      error.empId
                        ? "dropdown errorfocus mt-0 w-100"
                        : "dropdown inputfocus mt-0 w-100"
                    }
                    tabindex="0"
                  >
                    <div
                      className="event-dropdown "
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        className={
                          workerDetails?.empId === ROASTER_ROLENAME
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {workerDetails?.empId?.length > WORKER_NAME_LENGTH
                          ? workerDetails?.empId.substring(
                              0,
                              WORKER_NAME_LENGTH
                            ) + "..."
                          : workerDetails?.empId}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>

                    {filteredRoleList?.length > 0 && (
                      <ul className="dropdown-menu roasterdropsize thin-scrollbar w-100">
                        <div className="d-flex">
                          <img src={search} className="ms-2" alt="img" />
                          <input
                            type="text"
                            className=" managersearch ms-2 viewName"
                            name="search"
                            placeholder="Enter EmpId"
                            onChange={handleChangeForEmpId}
                            onKeyDown={(event) => {
                              if (event.key === ENTER_KEY) {
                                handleRoleSearch();
                              }
                            }}
                            value={roleSearchTerm}
                          />
                        </div>
                        {filteredRoleList?.map((item, index) => {
                          return (
                            <li key={index}>
                              <span
                                onClick={() =>
                                  handleChange(item, workerDetails?.empId)
                                }
                                className={
                                  workerDetails?.empId === ROASTER_WORKER_NAME
                                    ? "dropdown-item close-cursor viewName"
                                    : "dropdown-item close-cursor viewLabel"
                                }
                              >
                                {item?.empId}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                )}
                {error?.empId ? <p className="error">{error.empId}</p> : null}
              </div>
            </div>
            <div className="w-100 m-2">
              <label className="labels">Worker Name</label>

              <div className="mt-1 w-100">
                <input
                  className="form-control viewName p-2"
                  value={
                    workerDetails?.workerName?.length > WORKER_NAME_LENGTH
                      ? workerDetails.workerName.substring(
                          0,
                          WORKER_NAME_LENGTH
                        ) + "..."
                      : workerDetails.workerName
                  }
                  disabled
                ></input>
              </div>
            </div>
          </div>

          <div className="d-flex mt-1">
            <div className="w-100 m-2">
              <label className="labels">Role</label>
              <div className="mt-1 w-100">
                <input
                  className="form-control viewName p-2"
                  value={workerDetails.roleName}
                  disabled
                ></input>
              </div>
            </div>
            <div className="w-100 m-2">
              <label className="labels">Department</label>
              <div className="mt-1 w-100">
                <input
                  className="form-control viewName p-2"
                  value={workerDetails.deptName}
                  disabled
                ></input>
              </div>
            </div>
          </div>

          <div className="d-flex mt-1">
            <div className="w-100 m-2">
              <label className="labels mt-1">Shift Location</label>
              <span className="strike">*</span>
              <div
                className={
                  error.shiftLocation
                    ? "dropdown errorfocus mt-0 w-100"
                    : "dropdown inputfocus mt-0 w-100"
                }
              >
                <div
                  className="dropdown mt-0 w-100"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  tabindex="0"
                  onKeyDown={handleKeyPress}
                >
                  {rosterIdEditDetails ? (
                    <div className="event-dropdown">
                      <span
                        className={
                          shiftLocation?.shiftLocation === ROASTER_LOCATION
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftLocation.shiftLocation}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="event-dropdown"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        className={
                          shiftLocation?.shiftLocation === ROASTER_LOCATION
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftLocation.shiftLocation}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  )}
                  <ul className="dropdown-menu dropdown-2 thin-scrollbar">
                    {floorLists?.body?.map((item, index) => {
                      return (
                        <li key={index}>
                          <span
                            className="dropdown-item close-cursor"
                            onClick={() =>
                              handleChange(item, shiftLocation.shiftLocation)
                            }
                          >
                            {item?.shiftLocation}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {error?.shiftLocation ? (
                <p className="error">{error.shiftLocation}</p>
              ) : null}
            </div>

            <div className="w-100 m-2">
              <label className="labels mt-1">Shift Station</label>
              <span className="strike">*</span>
              <div
                className={
                  error.stationName
                    ? "dropdown errorfocus mt-0 w-100"
                    : "dropdown inputfocus mt-0 w-100"
                }
              >
                <div
                  className="dropdown mt-0 w-100"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  tabindex="0"
                  onKeyDown={handleKeyPress}
                >
                  {rosterIdEditDetails ? (
                    <div className="event-dropdown">
                      <span
                        className={
                          shiftStation?.shiftStationName === ROASTER_STATIONNAME
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftStation?.shiftStationName}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="event-dropdown"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        className={
                          shiftStation?.shiftStationName === ROASTER_STATIONNAME
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftStation?.shiftStationName}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  )}
                  <ul className="dropdown-menu dropdown-2 thin-scrollbar">
                    {stationLists?.body?.map((item, index) => {
                      return (
                        <li key={index}>
                          <span
                            className="dropdown-item close-cursor"
                            onClick={() =>
                              handleChange(item, shiftStation?.shiftStationName)
                            }
                          >
                            {item?.stationName}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {error?.stationName ? (
                <p className="error">{error.stationName}</p>
              ) : null}
            </div>
          </div>

          <div className="d-flex mt-1">
            <div className="w-100 m-2">
              <label className="labels mt-1">Shift Time Slots</label>
              <span className="strike">*</span>
              <div
                className={
                  error.shiftTime
                    ? "dropdown errorfocus mt-0 w-100"
                    : "dropdown inputfocus mt-0 w-100"
                }
              >
                <div
                  className="dropdown mt-0 w-100"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  tabindex="0"
                  onKeyDown={handleKeyPress}
                >
                  {rosterIdEditDetails ? (
                    <div className="event-dropdown">
                      <span
                        className={
                          shiftTimeSlots?.shiftTimeSlots === ROASTER_TIMESLOTS
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftTimeSlots.shiftTimeSlots}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="event-dropdown"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        className={
                          shiftTimeSlots?.shiftTimeSlots === ROASTER_TIMESLOTS
                            ? "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewLabel justify-content-between"
                            : "d-flex text-align-center align-middle mt-1 ms-2 mb-1 p-1 viewName justify-content-between"
                        }
                      >
                        {shiftTimeSlots.shiftTimeSlots}
                        <img src={dropDownArrow} alt="" className="m-1" />
                      </span>
                    </div>
                  )}
                  <ul className="dropdown-menu dropdown-2 thin-scrollbar">
                    {shiftTimeSlotLists?.body?.map((item, index) => {
                      return (
                        <li key={index}>
                          <span
                            className="dropdown-item close-cursor"
                            onClick={() =>
                              handleChange(item, shiftTimeSlots.shiftTimeSlots)
                            }
                          >
                            {item?.shiftStartTime + " - " + item?.shiftEndTime}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {error?.shiftTime ? (
                <p className="error">{error.shiftTime}</p>
              ) : null}
            </div>
            <div className="w-100 m-2">
              <label className="labels mt-1">Date Range</label>
              <span className="strike">*</span>

              <div className="mt-0 w-100 viewName">
                <DatePicker
                  value={dateRange}
                  onChange={onDateChange}
                  range
                  style={{
                    ...DATE_FIELD_STYLE,
                    borderColor:
                      error.startDate || error.endDate
                        ? "red"
                        : DATE_FIELD_STYLE.borderColor,
                  }}
                  placeholder="Select Date Range"
                  maxDate={futureDate}
                  minDate={pastDate}
                />

                {error?.startDate && error?.endDate ? (
                  <p className="error">{DATE_RANGE_ERROR}</p>
                ) : error?.startDate ? (
                  <p className="error">{error.startDate}</p>
                ) : (
                  <p className="error">{error.endDate}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modalHeader w-100 border border-end-0 border-top-1 border-bottom-0 border-start-0 align-items-center d-flex justify-content-between p-3"
          style={CANVAS_BOTTOM_STYLE}
        >
          <div
            onClick={resetfn}
            className="reset-button align-items-center d-flex px-3 justify-content-center rounded-2"
          >
            <span className="reset-text">Reset</span>
          </div>
          <div className="d-flex">
            {cancelFunRoaster ? (
              <div className="reset-button align-items-center d-flex px-3 justify-content-center rounded-2">
                <span
                  className="reset-text"
                  data-bs-dismiss="offcanvas"
                  onClick={clearPropsfn}
                >
                  Cancel
                </span>
              </div>
            ) : (
              <div
                className="reset-button align-items-center d-flex px-3 justify-content-center rounded-2"
                onClick={handleShowModal}
              >
                <span className="reset-text">Cancel</span>
              </div>
            )}
            <div
              className="save-button align-items-center ms-3 px-3 d-flex border justify-content-center rounded-2"
              type="submit"
              onClick={submit}
            >
              <span className="saveText">Save</span>
            </div>
          </div>
        </div>

        <CancelModal
          show={showModal}
          handleClose={handleCloseModal}
          resetCancel={cancelFn}
        />
      </div>
    </>
  );
};

export default AddRosterManagement;
